import axios from 'axios';
import _ from 'lodash';
import { message } from 'antd';
import { history } from '../store';
export const USERS_FETCHING = "USERS_FETCHING";
export const USERS_FETCHED_SUCCESS = "USERS_FETCHED_SUCCESS";
export const USERS_ADDING = "USERS_ADDING";
export const USERS_ADDED_SUCCESS = "USERS_ADDED_SUCCESS";
export const USERS_EDITING = "USERS_EDITING";
export const USERS_EDITTED_SUCCESS = "USERS_EDITTED_SUCCESS";
export const USERS_PATCHING = "USERS_PATCHING";
export const USERS_PATCHED_SUCCESS = "USERS_PATCHED_SUCCESS";
export const USERS_DELETING = "USERS_DELETING";
export const USERS_DELETED_SUCCESS = "USERS_DELETED_SUCCESS";
export const USERS_ERROR = "USERS_ERROR";

export const load = () => (
    async (dispatch, getState) => {
        dispatch({
            type: USERS_FETCHING
        });
        try {
            let requestParams = {
                filter: {
                    order: [
                        "userName ASC"
                    ]
                }
            }
            let response = await axios.get(process.env.REACT_APP_API_BASE_URI + '/api/users', { params: requestParams, headers: { Authorization: `Bearer ${getAccessToken()}` } });
            dispatch({
                type: USERS_FETCHED_SUCCESS,
                items: response.data
            });
        } catch (err) {
            message.error('Uživatele se nepodařilo načíst! Zkuste to později.');
            dispatch({
                type: USERS_ERROR
            });
        }
    }
);

export const add = (data) => (
    async (dispatch, getState) => {
        try {
            dispatch({ type: USERS_ADDING });
            let response = await axios.post(process.env.REACT_APP_API_BASE_URI + '/api/users', data, { headers: { Authorization: `Bearer ${getAccessToken()}` } });
            dispatch({
                type: USERS_ADDED_SUCCESS,
                item: response.data
            });
        } catch (err) {
            message.error('Uživatele se nepodařilo vložiz! Zkuste to později.');
            dispatch({
                type: USERS_ERROR
            });
        }
    }
);

export const edit = (data) => (
    async (dispatch, getState) => {

        try {
            dispatch({ type: USERS_EDITING });
            let id = data.id;
            delete data.id;
            await axios.patch(process.env.REACT_APP_API_BASE_URI + '/api/users/' + id, data, { headers: { Authorization: `Bearer ${getAccessToken()}` } });
            setTimeout(() => {
                dispatch({
                    type: USERS_EDITTED_SUCCESS,
                    item: Object.assign({}, data, { id: id })
                });
            }, Math.floor(Math.random() * 600) + 200);
        } catch (err) {
            message.error('Uživatele se nepodařilo editovat! Zkuste to později.');
            dispatch({
                type: USERS_ERROR
            });
        }
    }
);

export const patch = (data) => (
    async (dispatch, getState) => {

        try {
            dispatch({ type: USERS_PATCHING });
            let id = data.id;
            delete data.id;
            await axios.patch(process.env.REACT_APP_API_BASE_URI + '/api/users/' + id, data, { headers: { Authorization: `Bearer ${getAccessToken()}` } });
            setTimeout(() => {
                dispatch({
                    type: USERS_PATCHED_SUCCESS,
                    item: Object.assign({}, data, { id: id })
                });
            }, Math.floor(Math.random() * 600) + 200);
        } catch (err) {
            message.error('Uživatele se nepodařilo editovat! Zkuste to později.');
            dispatch({
                type: USERS_ERROR
            });
        }
    }
);

export const changePassword = (data) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            axios.patch(process.env.REACT_APP_API_BASE_URI + '/api/users/changePassword/' + getId(), data, { headers: { Authorization: `Bearer ${getAccessToken()}` } })
                .then(r => {
                    resolve('ok');
                })
                .catch(err => {
                    reject(err);
                });
        }, 1000);
    });
};

export const del = (id) => (
    async (dispatch, getState) => {

        try {
            dispatch({ type: USERS_DELETING });
            await axios.delete(process.env.REACT_APP_API_BASE_URI + '/api/users/' + id, { headers: { Authorization: `Bearer ${getAccessToken()}` } });
            dispatch({
                type: USERS_DELETED_SUCCESS,
                item: Object.assign({}, { id: id })
            });
        } catch (err) {
            message.error('Uživalete se nepodařil smazat! Zkuste to později.');
            dispatch({
                type: USERS_ERROR
            });
        }
    }
);

export const userLogin = (credentials, grant_type = 'password') => {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_BASE_URI + '/api/users/login', Object.assign({}, credentials, { grant_type: grant_type }))
            .then(r => {
                let decodedJwt = JSON.parse(Buffer.from(r.data.access_token.split('.')[1], 'base64').toString('utf8'));
                localStorage.setItem('user', JSON.stringify({
                    name: decodedJwt.name,
                    username: decodedJwt.username,
                    roles: decodedJwt.roles,
                    signs: decodedJwt.signs,
                    expiration: decodedJwt.exp,
                    access_token: r.data.access_token,
                    refresh_token: r.data.refresh_token
                }));
                resolve(JSON.parse(localStorage.getItem('user')));
            })
            .catch(err => {
                reject(err);
            });
    });
};

export const logout = () => {
    localStorage.clear();
    history.push("/signs");
};

export const isAuthenticated = () => {
    if (!localStorage.getItem('user')) {
        return false;
    }

    if (isTokenExpired(getAccessToken())) {
        logout();
        return false;
    } else {
        return true;
    }

    // NEW
    // if (this.isTokenExpired(this.getAccessToken())) {
    //   if (this.isTokenExpired(this.getRefreshToken())) {
    //     this.logout();
    //     return false;
    //   } else {
    //     return this.login({refresh_token: this.getRefreshToken()}, 'refresh_token')
    //       .then(() => {
    //         return true;
    //       });
    //   }

    // } else {
    //   return true;
    // }
}

export const getUser = () => {
    if (isAuthenticated()) {
        let decodedJwt = JSON.parse(Buffer.from(JSON.parse(localStorage.getItem('user')).access_token.split('.')[1], 'base64').toString('utf8'));
        return decodedJwt;
    } else {
        return null;
    }
}

export const getRoles = () => {
    if (isAuthenticated()) {
        return getUser().roles
            ? getUser().roles
            : null;
    } else {
        return [];
    }
}

export const isInRoles = (roles) => {
    let userRoles = getRoles();
    if (_.intersectionWith(userRoles, roles, _.isEqual).length > 0) {
        return true;
    } else {
        return false
    }
}

export const getName = () => {
    if (isAuthenticated()) {
        return getUser().name
            ? getUser().name
            : null;
    } else {
        return null;
    }
}

export const getId = () => {
    if (isAuthenticated()) {
        return getUser().uid
            ? getUser().uid
            : null;
    } else {
        return null;
    }
}

export const getUsername = () => {
    if (this.isAuthenticated()) {
        return this.getUser().username
            ? this.getUser().username
            : null;
    } else {
        return null;
    }
}

export const getAccessToken = () => {
    return JSON.parse(localStorage.getItem('user')).access_token;
}

export const getRefreshToken = () => {
    return JSON.parse(localStorage.getItem('user')).refresh_token;
}

export const isTokenExpired = (token) => {
    let decoded = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('utf8'));

    if (!decoded.exp) {
        return true;
    }

    if (decoded.exp <= (Date.now() / 1000)) {
        return true;
    } else {
        return false;
    }
}
