import { combineReducers } from 'redux';
import Drawer from './Drawer';
import BusSigns from './BusSigns';
import Operators from './Operators';
import Messages from './Messages';
import Users from './Users';
import Alerts from './Alerts';

export default combineReducers({
  drawer: Drawer,
  bussigns: BusSigns,
  operators: Operators,
  messages: Messages,
  users: Users,
  alerts: Alerts,
});
