import * as actions from '../actions/BusSigns';

export default (
  state = {
    items: [],
    isLoading: false,
    isLoading2: false,
  },
  action
) => {
  switch (action.type) {
    case actions.BUSSIGNS_FETCHING:
      return {
        ...state,
        items: [],
        isLoading: true
      };
    case actions.BUSSIGNS_FETCHED_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false
      };
    case actions.BUSSIGNS_DEPARTURES_FETCHING:
      return {
        ...state,
        departures: [],
        info: '',
        time: '',
        isLoading2: true
      };
    case actions.BUSSIGNS_DEPARTURES_FETCHED_SUCCESS:
      let departures = [];
      action.result.departures.map((item, idx) => (
        departures.push(Object.assign({}, item, { rowId: idx }))
      ));
      return {
        ...state,
        departures: departures,
        info: action.result.info,
        time: action.result.time,
        isLoading2: false
      };
    case actions.BUSSIGNS_ADDING:
      return {
        ...state,
        isLoading: true
      };
    case actions.BUSSIGNS_ADDED_SUCCESS:
      return {
        ...state,
        items: state.items.concat([action.item]),
        isLoading: false
      };
    case actions.BUSSIGNS_EDITING:
      return {
        ...state,
        isLoading: true
      };
    case actions.BUSSIGNS_EDITTED_SUCCESS:
      let aItem = action.item;
      return {
        ...state,
        items: state.items.map((item) => {
          return item.id === action.item.id
            ? { ...item, ...aItem }
            : item;
        }),
        isLoading: false
      };
    case actions.BUSSIGNS_PASSWD_CHANGING:
      return {
        ...state,
        isLoading: true
      };
    case actions.BUSSIGNS_PASSWD_CHANGING_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case actions.BUSSIGNS_DELETING:
      return {
        ...state,
        isLoading: true
      };
    case actions.BUSSIGNS_DELETED_SUCCESS:
      state.items.splice(state.items.indexOf(action.item), 1);
      return {
        ...state,
        items: state.items,
        isLoading: false
      };
    case actions.BUSSIGNS_ERROR:
      return {
        ...state,
        isLoading: false,
        isLoading2: false,
      };
    default:
      return state;
  }
};
