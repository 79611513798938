import React, { Component } from 'react'
import { Button, Form, Input, message } from 'antd';
import { connect } from 'react-redux';
import { changePassword } from '../actions/Users';
import { closeDrawer } from '../actions/Drawer';

class UserChangePasswordForm extends Component {
    formRef = React.createRef();

    componentDidMount() {
        this.formRef.current.setFieldsValue(this.props.defaultValues);
    }

    async onFinish(values) {
        if (values.confirmPassword) delete values.confirmPassword;
        try {
            changePassword(values).then(() => {
                this.props.closeDrawer();
                message.success('Heslo bylo změněno byl editován');
            })
                .catch(err => {
                    if (err.response) {
                        message.warning(err.response.data.error.message);
                    } else {
                        message.error(err);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }

    onFinishFailed({ errorFields }) {
        console.log(errorFields)
        this.formRef.current.scrollToField(errorFields[0].name);
    }

    render() {
        return (
            <React.Fragment>
                <Form
                    labelCol={{
                        xs: {
                            span: 24,
                        },
                        sm: {
                            span: 8,
                        },
                    }}
                    wrapperCol={{
                        xs: {
                            span: 24,
                        },
                        sm: {
                            span: 16,
                        },
                    }}
                    onFinish={this.onFinish.bind(this)}
                    onFinishFailed={this.onFinishFailed.bind(this)}
                    ref={this.formRef}
                    scrollToFirstError
                >
                    <Form.Item
                        wrapperCol={{ span: 7 }}
                        name="oldPassword"
                        label="Původní heslo"
                        rules={[
                            { required: true, message: 'Vyplňte původní heslo!', },
                        ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{ span: 7 }}
                        name="newPassword"
                        label="Nové heslo"
                        rules={[
                            { required: true, message: 'Vyplňte nové heslo!', },
                            { pattern: new RegExp("^[A-Za-z0-9+*%]*$"), message: "Nepovolený znak!" },
                            { min: 8, message: "Heslo musí mít minimálně 8 znaků!" },
                        ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{ span: 7 }}
                        name="confirmPassword"
                        label="Potvrdit nové heslo"
                        dependencies={['newPassword']}
                        hasFeedback
                        rules={[
                            { required: true, message: 'Vyplňte heslo pro potvrzení!', },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('Zadaná hesla se neshodují!');
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 13, offset: 5 }}>
                        <Button type="primary" htmlType="submit" loading={this.props.bussigns.isLoading}>
                            Uložit
                        </Button>
                    </Form.Item>
                </Form>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => ({
    bussigns: state.bussigns
});

export default connect(
    mapStateToProps,
    { closeDrawer }
)(UserChangePasswordForm)

