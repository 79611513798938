import * as actions from '../actions/Alerts';

export default (
    state = {
        items: [],
        isLoading: false
    },
    action
) => {
    switch (action.type) {
        case actions.ALERTS_FETCHING:
            return {
                ...state,
                items: [],
                isLoading: true
            };
        case actions.ALERTS_FETCHED_SUCCESS:
            return {
                ...state,
                items: action.items,
                isLoading: false
            };
        case actions.ALERTS_DELETING:
            return {
                ...state,
                isLoading: true
            };
        case actions.ALERTS_DELETED_SUCCESS:
            state.items.splice(state.items.indexOf(action.item), 1);
            return {
                ...state,
                items: state.items,
                isLoading: false
            };
        case actions.ALERTS_ERROR:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state;
    }
};
