import axios from 'axios';
import { message } from 'antd';
import { getAccessToken } from './Users';

export const ALERTS_FETCHING = "ALERTS_FETCHING";
export const ALERTS_FETCHED_SUCCESS = "ALERTS_FETCHED_SUCCESS";
export const ALERTS_DELETING = "ALERTS_DELETING";
export const ALERTS_DELETED_SUCCESS = "ALERTS_DELETED_SUCCESS";
export const ALERTS_ERROR = "ALERTS_ERROR";

export const load = (dateFrom = null, dateTo = null) => (
    async (dispatch, getState) => {
        dispatch({
            type: ALERTS_FETCHING
        });
        try {
            let requestParams = {
                filter: {
                    order: [
                        "timestamp DESC"
                    ],
                    where: {
                        and: []
                    },
                }
            }
            if (dateFrom && dateTo) {
                requestParams.filter.where.and.push({ timestamp: { gte: dateFrom } });
                requestParams.filter.where.and.push({ timestamp: { lte: dateTo } });
            }
            let response = await axios.get(process.env.REACT_APP_API_BASE_URI + '/api/alerts', { params: requestParams, headers: { Authorization: `Bearer ${getAccessToken()}` } });
            dispatch({
                type: ALERTS_FETCHED_SUCCESS,
                items: response.data
            });
        } catch (err) {
            message.error('Alerty se nepodařilo načíst! Zkuste to později');
            dispatch({
                type: ALERTS_ERROR
            });
        }
    }
);

export const del = (id) => (
    async (dispatch, getState) => {

        try {
            dispatch({ type: ALERTS_DELETING });
            await axios.delete(process.env.REACT_APP_API_BASE_URI + '/api/alerts/' + id, { headers: { Authorization: `Bearer ${getAccessToken()}` } });
            dispatch({
                type: ALERTS_DELETED_SUCCESS,
                item: Object.assign({}, { id: id })
            });
        } catch (err) {
            message.error('Alert se nepodařilo smazat! Zkuste to později.');
            dispatch({
                type: ALERTS_ERROR
            });
        }
    }
);
