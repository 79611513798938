import { Button, Form, Input, message, Transfer } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { add, edit, load } from '../actions/Users';
import { getRoles } from '../actions/Roles';
import { getSignsNames } from '../actions/BusSigns';
import { closeDrawer } from '../actions/Drawer';
import UsersFromPasswordItems from './UsersFromPasswordItems';

class UsersForm extends React.Component {

    formRef = React.createRef();

    state = {
        transferKeysRoles: [],
        transferTargetKeysRoles: [],
        transferKeysSigns: [],
        transferTargetKeysSigns: []
    }

    componentDidMount() {
        getRoles()
            .then(data => {
                this.setState({
                    transferKeysRoles: data
                })
                if (this.props.defaultValues) {
                    this.setState({ transferTargetKeysRoles: this.props.defaultValues.roles });
                }
            })
            .catch(err => {
                message.error('Chyba při čtení rolí!');
            })
        getSignsNames()
            .then(data => {
                this.setState({
                    transferKeysSigns: data
                })
                if (this.props.defaultValues) {
                    this.setState({ transferTargetKeysSigns: this.props.defaultValues.signs });
                }
            })
            .catch(err => {
                message.error('Chyba při čtení rolí!');
            })
        this.formRef.current.setFieldsValue(this.props.defaultValues);
    }

    async onFinish(values) {
        if (values.confirmPassword) delete values.confirmPassword;
        if (values.id === undefined) {
            await this.props.add(values);
            this.formRef.current.resetFields();
            message.success('Uživatel byl vložen');
        } else {
            await this.props.edit(values);
            message.success('Uživatel byl editován');
        }
        this.props.closeDrawer();
    }

    onFinishFailed({ errorFields }) {
        console.log(errorFields)
        this.formRef.current.scrollToField(errorFields[0].name);
    }

    handleTransferChangeRoles(targetKeys, direction, moveKeys) {
        this.setState({ transferTargetKeysRoles: targetKeys });
    }

    handleTransferChangeSigns(targetKeys, direction, moveKeys) {
        this.setState({ transferTargetKeysSigns: targetKeys });
    }

    render() {
        return (
            <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 18 }}
                onFinish={this.onFinish.bind(this)}
                onFinishFailed={this.onFinishFailed.bind(this)}
                ref={this.formRef}
            >
                {this.props.defaultValues && this.props.defaultValues.id
                    ? <Form.Item name="id" label="ID" rules={[{ required: true }]} hidden={true}>
                        <Input disabled={true} />
                    </Form.Item>
                    : null}

                <Form.Item wrapperCol={{ span: 8 }}
                    name="name"
                    label="Celé jméno"
                    rules={[{ required: true, message: 'Vyplňte jméno!', },]} hasFeedback>
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 8 }}
                    name="userName"
                    label="Uživatelské jméno"
                    rules={[{ required: true, message: 'Vyplňte uživatelské jméno!' }]} hasFeedback>
                    <Input />
                </Form.Item>
                {(!this.props.defaultValues) ?
                    <UsersFromPasswordItems />
                    : null}
                <Form.Item
                    name="roles"
                    label="Role"
                    rules={[
                        { required: true, message: "Vyberte role pro uživatele", }
                    ]}
                    hasFeedback>
                    <Transfer
                        listStyle={{
                            width: 490,
                            height: 300,
                        }}
                        locale={{
                            notFoundContent: 'Žádné role',
                            itemUnit: ''
                        }}
                        dataSource={this.state.transferKeysRoles}
                        render={item => `${item.key} - ${item.description}`}
                        targetKeys={this.state.transferTargetKeysRoles}
                        onChange={this.handleTransferChangeRoles.bind(this)}
                    >
                    </Transfer>
                </Form.Item>
                <Form.Item
                    name="signs"
                    label="Označníky"
                    rules={[
                        { required: true, message: "Vyberte označníky pro uživatele", }
                    ]}
                    hasFeedback>
                    <Transfer
                        listStyle={{
                            width: 490,
                            height: 300,
                        }}
                        locale={{
                            notFoundContent: 'Žádné označníky',
                            itemUnit: ''
                        }}
                        dataSource={this.state.transferKeysSigns}
                        render={item => (item.serialNumber === '*') ? '* - všechny označníky' : `${item.serialNumber}`}
                        targetKeys={this.state.transferTargetKeysSigns}
                        onChange={this.handleTransferChangeSigns.bind(this)}
                    >
                    </Transfer>
                </Form.Item>
                <Form.Item wrapperCol={{ span: 13, offset: 5 }}>
                    <Button type="primary" htmlType="submit" loading={this.props.users.isLoading}>
                        {this.props.defaultValues && this.props.defaultValues.id
                            ? "Uložit"
                            : "Přidat"}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

const mapStateToProps = state => ({
    users: state.users
});

export default connect(
    mapStateToProps,
    { add, edit, load, closeDrawer }
)(UsersForm)