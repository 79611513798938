import { PageHeader } from 'antd';
import React from 'react'
import { connect } from 'react-redux';
import { openDrawer } from '../actions/Drawer';
import UsersList from '../components/UsersList';

class Users extends React.Component {
    render() {
        document.title = 'Uživatelé | Smart4City';
        return (
            <div>
                <PageHeader
                    title="Uživatelé"
                    subTitle="seznam uživatelů a jejich nastavení"
                    extra={[]}
                >
                    <UsersList />
                </PageHeader>
            </div>
        )
    }
}
const mapStateToProps = state => ({
});

export default connect(
    mapStateToProps,
    { openDrawer }
)(Users)
