import { PageHeader } from 'antd';
import React from 'react'
import { connect } from 'react-redux';
import { openDrawer } from '../actions/Drawer';
import MessagesList from '../components/MessagesList';

class Messages extends React.Component {
    render() {
        document.title = 'Zprávy | Smart4City';
        return (
            <div>
                <PageHeader
                    title="Zprávy"
                    subTitle="seznam zpráv z autobusových označníků"
                    extra={[]}
                >
                    <MessagesList />
                </PageHeader>
            </div>
        )
    }
}
const mapStateToProps = state => ({
});

export default connect(
  mapStateToProps,
  { openDrawer }
)(Messages)
