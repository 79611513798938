import { PageHeader } from 'antd';
import React from 'react'
import { connect } from 'react-redux';
import { openDrawer } from '../actions/Drawer';
import OperatorsList from '../components/OperatorsList';

class Operators extends React.Component {
    render() {
        document.title = 'Operátoři | Smart4City';
        return (
            <div>
                <PageHeader
                    title="Operátoři"
                    subTitle="seznam operátorů autobusových označníků"
                    extra={[]}
                >
                    <OperatorsList />
                </PageHeader>
            </div>
        )
    }
}
const mapStateToProps = state => ({
});

export default connect(
  mapStateToProps,
  { openDrawer }
)(Operators)
