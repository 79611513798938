import * as actions from '../actions/Operators';

export default (
  state = {
    items: [],
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case actions.OPERATORS_FETCHING:
      return {
        ...state,
        items: [],
        isLoading: true
      };
    case actions.OPERATORS_FETCHED_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false
      };
    case actions.OPERATORS_ADDING:
      return {
        ...state,
        isLoading: true
      };
    case actions.OPERATORS_ADDED_SUCCESS:
      return {
        ...state,
        items: state.items.concat([action.item]),
        isLoading: false
      };
    case actions.OPERATORS_EDITING:
      return {
        ...state,
        isLoading: true
      };
    case actions.OPERATORS_EDITTED_SUCCESS:
      let aItem = action.item;
      return {
        ...state,
        items: state.items.map((item) => {
          return item.id === action.item.id
            ? { ...item, ...aItem }
            : item;
        }),
        isLoading: false
      };
    case actions.OPERATORS_DELETING:
      return {
        ...state,
        isLoading: true
      };
    case actions.OPERATORS_DELETED_SUCCESS:
      state.items.splice(state.items.indexOf(action.item), 1);
      return {
        ...state,
        items: state.items,
        isLoading: false
      };
    case actions.OPERATORS_ERROR:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};
