import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Spin, Table, Button, Tooltip, Popconfirm, message, Row, Col, ConfigProvider, Empty, List } from 'antd';
import { PlusOutlined, DeleteOutlined, SyncOutlined } from '@ant-design/icons';
import { load, del } from '../actions/Users';
import { openDrawer } from '../actions/Drawer';
import UsersForm from './UsersForm';
import UsersFromPassword from './UsersFromPassword';
import { isInRoles } from '../actions/Users';

class UsersList extends Component {

    _asyncRequest = this.props.load();

    async deleteUser(id) {
        await this.props.del(id);
        message.success('Uživatel byl smazán');
        //await this.props.load();
    }
    rowExpander(record) {
        return (
            <React.Fragment>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <List size="small"
                            header={<div>Role</div>}
                            bordered
                            dataSource={record.roles}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />
                    </Col>
                    <Col span={8}>
                        <List size="small"
                            header={<div>Označníky</div>}
                            bordered
                            dataSource={record.signs}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

    render() {
        if (this.props.users.isLoading) {
            return (
                <Spin />
            );
        } else {
            return (
                <React.Fragment>
                    <Row>
                        <Col span="12">
                            {isInRoles(['ADMIN', 'USERS_WRITE']) ?
                                <Tooltip title="Přidat nového uživatele">
                                    <Button
                                        key={1}
                                        type="link"
                                        icon={<PlusOutlined />}
                                        title="Vložit nového uživatele"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.openDrawer(
                                                'Přidání nového uživatele',
                                                <UsersForm />,
                                                1200);
                                        }}
                                    />
                                </Tooltip>
                                : null}
                        </Col>
                        <Col span="12" style={{ textAlign: 'right' }}>
                            <Button
                                type="default"
                                icon={<SyncOutlined />}
                                onClick={() => { this.props.load() }}
                                style={{ marginRight: 10, marginLeft: 15 }}>
                            </Button>
                        </Col>
                    </Row>
                    <ConfigProvider renderEmpty={() => <Empty description="Žádní uživatelé" image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
                        <Table
                            rowKey="id"
                            locale={{
                                filterEmptyText: 'Žádné filtry',
                                triggerDesc: 'Klikni pro řazení sestupně',
                                triggerAsc: 'Klikni pro řazení vzestupně',
                                cancelSort: 'Klikni pro zrušení řazení',
                            }}
                            columns={[
                                {
                                    title: 'Uživatelské jméno',
                                    dataIndex: 'userName',
                                    key: 'userName',
                                    width: 350,
                                    sorter: (a, b) => a.userName.localeCompare(b.userName),
                                    defaultSortOrder: 'ascend',
                                }, {
                                    title: 'Jméno',
                                    dataIndex: 'name',
                                    key: 'name',
                                    sorter: (a, b) => a.name.localeCompare(b.name),
                                }, {
                                    title: 'Akce',
                                    dataIndex: 'actions',
                                    key: 'actions',
                                    render: (text, record, idx) => {
                                        if (record.userName !== 'admin' && isInRoles(['ADMIN', 'USERS_WRITE'])) {
                                            return (
                                                <React.Fragment>
                                                    <Button
                                                        key={1}
                                                        type="link"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.openDrawer(
                                                                'Editace uživatele: ' + record.name,
                                                                <UsersForm defaultValues={{
                                                                    id: record.id,
                                                                    name: record.name,
                                                                    userName: record.userName,
                                                                    roles: record.roles,
                                                                    signs: record.signs,
                                                                }} />,
                                                                1200);
                                                        }}
                                                    >
                                                        edit
                                                    </Button>
                                                    <Button
                                                        key={2}
                                                        type="link"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.openDrawer(
                                                                'Nastavení hesla uživatele: ' + record.name,
                                                                <UsersFromPassword defaultValues={{
                                                                    id: record.id,
                                                                }} />,
                                                                800);
                                                        }}
                                                    >
                                                        změnit heslo
                                                    </Button>
                                                    <Popconfirm
                                                        title="Opravdu smazat?"
                                                        onConfirm={(e) => {
                                                            e.preventDefault();
                                                            this.deleteUser(record.id);
                                                        }}
                                                        okText="Ano"
                                                        cancelText="Ne"
                                                        icon={<DeleteOutlined style={{ color: 'red' }} />}
                                                    >
                                                        <Button
                                                            key={3}
                                                            type="link"
                                                            danger
                                                        >
                                                            smazat</Button>
                                                    </Popconfirm>
                                                </React.Fragment>
                                            )
                                        }
                                    }
                                }
                            ]}
                            dataSource={this.props.users.items}
                            expandable={{
                                expandedRowRender: this.rowExpander,
                            }}
                            pagination={{
                                total: this.props.users.length,
                                pageSize: this.props.users.length,
                                hideOnSinglePage: true
                            }}
                        />
                    </ConfigProvider>
                </React.Fragment>
            );

        }
    }
}
const mapStateToProps = state => ({
    users: state.users
});
export default connect(
    mapStateToProps,
    { load, del, openDrawer }
)(UsersList)
