import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Alert, Drawer, Layout, Menu } from 'antd';
import * as AntdIcons from '@ant-design/icons';
import { UserOutlined } from '@ant-design/icons';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import { openDrawer, closeDrawer } from '../actions/Drawer';
import BusSigns from './BusSigns';
import Operators from './Operators';
import Messages from './Messages';
import NotFound from './NotFound';
import { logout, getName, isInRoles } from '../actions/Users';
import UserChangePasswordForm from '../components/UserChangePasswordForm';
import Alerts from './Alerts';
import Users from './Users';

const menuItems = [
    {
        key: "sub1", label: "Označníky a cedule", icon: "BarsOutlined", roles: ['ADMIN', 'SIGNS_READ', 'OPERATORS_READ', 'MESSAGES_READ'], subItems: [
            { key: 1, component: BusSigns, label: 'Označníky', path: '/signs', roles: ['ADMIN', 'SIGNS_READ'] },
            { key: 2, component: Operators, label: 'Operátoři', path: '/operators', roles: ['ADMIN', 'OPERATORS_READ'] },
            { key: 3, component: Messages, label: 'Zprávy', path: '/messages', roles: ['ADMIN', 'MESSAGES_READ'] },
        ]
    },
    {
        key: "sub2", label: "Alerty", icon: "WarningOutlined", roles: ['ADMIN', 'ALERTS_READ'], subItems: [
            { key: 1, component: Alerts, label: 'Všechny alerty', path: '/alerts', roles: ['ADMIN', 'ALERTS_READ'] },
        ]
    },
    {
        key: "sub3", label: "Nastavení", icon: "SettingOutlined", roles: ['ADMIN', 'USERS_READ'], subItems: [
            { key: 1, component: Users, label: 'Uživatelé', path: '/users', roles: ['ADMIN', 'USERS_READ'] }
        ]
    }
]

const { Content, Header, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class Protected extends React.Component {
    state = {
        collapsed: false,
    };

    onCollapse = collapsed => {
        this.setState({ collapsed });
    };

    onHeaderMenuClick(e) {
        if (e.key === 'logout') {
            logout();
        }

        if (e.key === 'changePassword') {
            this.props.openDrawer(
                'Změna hesla',
                <UserChangePasswordForm />,
                800);
        }
    }

    render() {
        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Sider width={220} collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
                    {this.state.collapsed
                        ? <div className="logo" style={{ width: 41, height: 32, background: 'url("https://smart4city.cz/img/s4c_logo_32_1.png")', margin: 16, marginLeft: 19 }} />
                        : <div className="logo" style={{ width: 160, height: 32, background: 'url("https://smart4city.cz/img/s4c_logo_32.png")', margin: 16, marginLeft: 34 }} />}
                    <Menu theme="dark" width={220} defaultOpenKeys={['sub1']} selectedKeys={[this.props.location.pathname]} mode="inline">
                        {menuItems.map((menuItem, midx) => {
                            if (menuItem.subItems !== undefined && isInRoles(menuItem.roles)) {
                                const AntdIcon = menuItem.icon ? AntdIcons[menuItem.icon] : null;
                                return (
                                    <SubMenu key={menuItem.key} icon={AntdIcon ? <AntdIcon /> : null} title={menuItem.label}>
                                        {
                                            menuItem.subItems.map((subItem, sidx) => {
                                                if (isInRoles(subItem.roles)) {
                                                    return (
                                                        <Menu.Item key={subItem.path}><Link to={subItem.path}>{subItem.label}</Link></Menu.Item>
                                                    );
                                                } else return null;
                                            })
                                        }
                                    </SubMenu>
                                )
                            } else return null;
                        })}
                    </Menu>
                </Sider>
                <Layout className="site-layout">
                    {process.env.NODE_ENV === 'development'
                        ? <Alert message="Development mode" banner />
                        : null}
                    <Header className="site-layout-background" style={{ padding: 0 }}>
                        <Menu mode="horizontal" onClick={this.onHeaderMenuClick.bind(this)} selectable={false}>
                            <Menu.SubMenu
                                style={{
                                    float: 'right'
                                }}
                                title={<span>
                                    <UserOutlined />
                                    {getName()}
                                </span>} >
                                <Menu.Item key="changePassword">
                                    Změnit heslo
                                </Menu.Item>
                                <Menu.Item key="logout">
                                    Odhlásit se
                                </Menu.Item>
                            </Menu.SubMenu>
                        </Menu>
                    </Header>
                    <Content style={{ margin: '0 16px' }}>
                        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                            <Switch>
                                {
                                    menuItems.map((menuItem) => {
                                        if (menuItem.subItems !== undefined) {
                                            return (
                                                menuItem.subItems.map((subItem) => {
                                                    if (isInRoles(subItem.roles)) {
                                                        return (
                                                            <Route key={subItem.key} exact path={subItem.path} component={subItem.component} />
                                                        );
                                                    } else return null;
                                                })
                                            )
                                        } else return null;
                                    })
                                }
                                <Route key={666} exact path="*" component={NotFound} />
                            </Switch>
                        </div>
                        <Drawer
                            title={this.props.drawer.title}
                            width={this.props.drawer.width}
                            visible={this.props.drawer.visible}
                            closable={true}
                            onClose={() => this.props.closeDrawer()}
                        >
                            {this.props.drawer.content}
                        </Drawer>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>Smart4City ©{moment().format('YYYY')} Created by SPEL a.s.</Footer>
                </Layout>
            </Layout>
        )
    }
}
const mapStateToProps = state => ({
    drawer: state.drawer
});

export default withRouter(connect(
    mapStateToProps,
    { openDrawer, closeDrawer }
)(Protected))
