import { Button, Form, Input, message} from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { add, edit, load } from '../actions/Operators';
import { closeDrawer } from '../actions/Drawer';

class OperatorsForm extends React.Component {

  formRef = React.createRef();

  componentDidMount() {
    this.formRef.current.setFieldsValue(this.props.defaultValues);
  }

  async onFinish(values) {
    if (values.id === undefined) {
      await this.props.add(values);
      this.formRef.current.resetFields();
      message.success('Operátor byl vložen');
    } else {
      await this.props.edit(values);
      message.success('Operátor byl editován');
    }
    this.props.closeDrawer();
  }

  onFinishFailed({errorFields}) {
    console.log(errorFields)
    this.formRef.current.scrollToField(errorFields[0].name);
  }

  render() {
    return (
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 15 }}
        onFinish={this.onFinish.bind(this)}
        onFinishFailed={this.onFinishFailed.bind(this)}
        ref={this.formRef}
      >
        {this.props.defaultValues && this.props.defaultValues.id
          ? <Form.Item name="id" label="ID" rules={[{ required: true }]} hidden={true}>
              <Input disabled={true} />
          </Form.Item>
          : null}

        <Form.Item name="name" label="Název" rules={[ { required: true, message: 'Vyplňte název!', },]} hasFeedback>
            <Input />
        </Form.Item>
        <Form.Item name="description" label="Popis">
            <Input />
        </Form.Item>
        <Form.Item name="moduleURL" label="URL modulu" rules={[{ required: true, message: 'Vyplňte URL modulu!' }]}hasFeedback>
            <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 13, offset: 5 }}>
          <Button type="primary" htmlType="submit" loading={this.props.operators.isLoading}>
            {this.props.defaultValues && this.props.defaultValues.id
              ? "Uložit"
              : "Přidat"}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  operators: state.operators
});

export default connect(
  mapStateToProps,
  { add, edit, load, closeDrawer }
)(OperatorsForm)