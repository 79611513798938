import * as actions from '../actions/Messages';

export default (
  state = {
    items: [],
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case actions.MESSAGES_FETCHING:
      return {
        ...state,
        items: [],
        isLoading: true
      };
    case actions.MESSAGES_FETCHED_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false
      };
    case actions.MESSAGES_DELETING:
      return {
        ...state,
        isLoading: true
      };
    case actions.MESSAGES_DELETED_SUCCESS:
      state.items.splice(state.items.indexOf(action.item), 1);
      return {
        ...state,
        items: state.items,
        isLoading: false
      };
    case actions.MESSAGES_ERROR:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};
