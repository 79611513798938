import { Button, Form, Input, message } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { edit } from '../actions/Users';
import { closeDrawer } from '../actions/Drawer';
import UsersFromPasswordItems from './UsersFromPasswordItems';

class UsersFormPassword extends React.Component {

    formRef = React.createRef();

    componentDidMount() {
        this.formRef.current.setFieldsValue(this.props.defaultValues);
    }

    async onFinish(values) {
        if (values.confirmPassword) delete values.confirmPassword;
        await this.props.edit(values);
        message.success('Heslo bylo změněno.');
        this.props.closeDrawer();
    }

    onFinishFailed({ errorFields }) {
        console.log(errorFields)
        this.formRef.current.scrollToField(errorFields[0].name);
    }

    render() {
        return (
            <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 18 }}
                onFinish={this.onFinish.bind(this)}
                onFinishFailed={this.onFinishFailed.bind(this)}
                ref={this.formRef}
            >
                <Form.Item name="id" label="ID" rules={[{ required: true }]} hidden={true}>
                    <Input disabled={true} />
                </Form.Item>

                <UsersFromPasswordItems />
                <Form.Item wrapperCol={{ span: 13, offset: 5 }}>
                    <Button type="primary" htmlType="submit" loading={this.props.users.isLoading}>
                        Uložit
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

const mapStateToProps = state => ({
    users: state.users
});

export default connect(
    mapStateToProps,
    { edit, closeDrawer }
)(UsersFormPassword)