import axios from 'axios';
import { message } from 'antd';
import { getAccessToken } from './Users';

export const OPERATORS_FETCHING = "OPERATORS_FETCHING";
export const OPERATORS_FETCHED_SUCCESS = "OPERATORS_FETCHED_SUCCESS";
export const OPERATORS_ADDING = "OPERATORS_ADDING";
export const OPERATORS_ADDED_SUCCESS = "OPERATORS_ADDED_SUCCESS";
export const OPERATORS_EDITING = "OPERATORS_EDITING";
export const OPERATORS_EDITTED_SUCCESS = "OPERATORS_EDITTED_SUCCESS";
export const OPERATORS_DELETING = "OPERATORS_DELETING";
export const OPERATORS_DELETED_SUCCESS = "OPERATORS_DELETED_SUCCESS";
export const OPERATORS_ERROR = "OPERATORS_ERROR";

export const load = () => (
    async (dispatch, getState) => {
        dispatch({
            type: OPERATORS_FETCHING
        });
        try {
            let requestParams = {
                filter: {
                    order: [
                        "name ASC"
                    ]
                }
            }
            let response = await axios.get(process.env.REACT_APP_API_BASE_URI + '/api/operators', { params: requestParams, headers: { Authorization: `Bearer ${getAccessToken()}` } });
            dispatch({
                type: OPERATORS_FETCHED_SUCCESS,
                items: response.data
            });
        } catch (err) {
            message.error('Operátory se nepodařilo načíst! Zkuste to později');
            dispatch({
                type: OPERATORS_ERROR
            });
        }
    }
);

export const add = (data) => (
    async (dispatch, getState) => {
        try {
            dispatch({ type: OPERATORS_ADDING });
            let response = await axios.post(process.env.REACT_APP_API_BASE_URI + '/api/operators', data, { headers: { Authorization: `Bearer ${getAccessToken()}` } });
            dispatch({
                type: OPERATORS_ADDED_SUCCESS,
                item: response.data
            });
        } catch (err) {
            message.error('Operátora se nepodařilo vložit! Zkuste to později');
            dispatch({
                type: OPERATORS_ERROR
            });
        }
    }
);

export const edit = (data) => (
    async (dispatch, getState) => {

        try {
            dispatch({ type: OPERATORS_EDITING });
            let id = data.id;
            delete data.id;
            await axios.patch(process.env.REACT_APP_API_BASE_URI + '/api/operators/' + id, data, { headers: { Authorization: `Bearer ${getAccessToken()}` } });
            setTimeout(() => {
                dispatch({
                    type: OPERATORS_EDITTED_SUCCESS,
                    item: Object.assign({}, data, { id: id })
                });
            }, Math.floor(Math.random() * 600) + 200);
        } catch (err) {
            message.error('Operátora se nepodařilo editovat! Zkuste to později');
            dispatch({
                type: OPERATORS_ERROR
            });
        }
    }
);

export const del = (id) => (
    async (dispatch, getState) => {

        try {
            dispatch({ type: OPERATORS_DELETING });
            await axios.delete(process.env.REACT_APP_API_BASE_URI + '/api/operators/' + id, { headers: { Authorization: `Bearer ${getAccessToken()}` } });
            dispatch({
                type: OPERATORS_DELETED_SUCCESS,
                item: Object.assign({}, { id: id })
            });
        } catch (err) {
            message.error('Operátora se nepodařilo smazat! Zkuste to později.');
            dispatch({
                type: OPERATORS_ERROR
            });
        }
    }
);
