import axios from 'axios';
import { message } from 'antd';
import { getAccessToken } from './Users';

export const BUSSIGNS_FETCHING = "BUSSIGNS_FETCHING";
export const BUSSIGNS_FETCHED_SUCCESS = "BUSSIGNS_FETCHED_SUCCESS";
export const BUSSIGNS_DEPARTURES_FETCHING = "BUSSIGNS_DEPARTURES_FETCHING";
export const BUSSIGNS_DEPARTURES_FETCHED_SUCCESS = "BUSSIGNS_DEPARTURES_FETCHED_SUCCESS";
export const BUSSIGNS_ADDING = "BUSSIGNS_ADDING";
export const BUSSIGNS_ADDED_SUCCESS = "BUSSIGNS_ADDED_SUCCESS";
export const BUSSIGNS_EDITING = "BUSSIGNS_EDITING";
export const BUSSIGNS_EDITTED_SUCCESS = "BUSSIGNS_EDITTED_SUCCESS";
export const BUSSIGNS_PASSWD_CHANGING = "BUSSIGNS_PASSWD_CHANGING";
export const BUSSIGNS_PASSWD_CHANGING_SUCCESS = "BUSSIGNS_PASSWD_CHANGING_SUCCESS";
export const BUSSIGNS_ERROR = "BUSSIGNS_ERROR";
export const BUSSIGNS_DELETING = "BUSSIGNS_DELETING";
export const BUSSIGNS_DELETED_SUCCESS = "BUSSIGNS_DELETED_SUCCESS";

export const load = () => (
    async (dispatch, getState) => {
        dispatch({
            type: BUSSIGNS_FETCHING
        });
        try {
            let requestParams = {
                filter: {
                    order: [
                        "signLastCommunication DESC"
                    ],
                    include: [{
                        relation: 'operator'
                    }]
                }
            }
            let response = await axios.get(process.env.REACT_APP_API_BASE_URI + '/api/signs', { params: requestParams, headers: { Authorization: `Bearer ${getAccessToken()}` } });
            dispatch({
                type: BUSSIGNS_FETCHED_SUCCESS,
                items: response.data
            });
        } catch (err) {
            message.error('Označníky se nepodařilo načíst! Zkuste to později.');
            dispatch({
                type: BUSSIGNS_ERROR
            });
        }
    }
);

export const getSignsNames = () => {
    return new Promise((resolve, reject) => {
        let requestParams = {
            filter: {
                order: [
                    "serialNumber ASC"
                ],
                fields: {
                    "serialNumber": true
                }
            }
        }
        axios.get(process.env.REACT_APP_API_BASE_URI + '/api/signs', { params: requestParams, headers: { Authorization: `Bearer ${getAccessToken()}` } })
            .then((response) => {
                let signs = [];
                signs.push({ key: "*", serialNumber: "*" })
                response.data.map((sign) => (
                    signs.push(Object.assign({}, sign, { key: sign.serialNumber }))
                ))
                resolve(signs);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getDepartures = (signId) => (
    async (dispatch, getState) => {
        dispatch({
            type: BUSSIGNS_DEPARTURES_FETCHING
        });
        try {
            let response = await axios.get(process.env.REACT_APP_API_BASE_URI + '/api/signs/' + signId + '/departures2User', { headers: { Authorization: `Bearer ${getAccessToken()}` } });
            dispatch({
                type: BUSSIGNS_DEPARTURES_FETCHED_SUCCESS,
                result: response.data.result
            });
        } catch (err) {
            message.error('Odjezdy se nepodařilo načíst! Zkuste to později.');
            dispatch({
                type: BUSSIGNS_ERROR
            });
        }
    }
);

export const add = (data) => (
    async (dispatch, getState) => {
        try {
            dispatch({ type: BUSSIGNS_ADDING });
            let response = await axios.post(process.env.REACT_APP_API_BASE_URI + '/api/signs', data, { headers: { Authorization: `Bearer ${getAccessToken()}` } });
            dispatch({
                type: BUSSIGNS_ADDED_SUCCESS,
                item: response.data
            });
        } catch (err) {
            message.error('Označník se nepodařilo vložiz! Zkuste to později.');
            dispatch({
                type: BUSSIGNS_ERROR
            });
        }
    }
);

export const edit = (data) => (
    async (dispatch, getState) => {

        try {
            dispatch({ type: BUSSIGNS_EDITING });
            let id = data.id;
            delete data.id;
            await axios.patch(process.env.REACT_APP_API_BASE_URI + '/api/signs/' + id, data, { headers: { Authorization: `Bearer ${getAccessToken()}` } });
            setTimeout(() => {
                dispatch({
                    type: BUSSIGNS_EDITTED_SUCCESS,
                    item: Object.assign({}, data, { id: id })
                });
            }, Math.floor(Math.random() * 600) + 200);
        } catch (err) {
            message.error('Označník se nepodařilo editovat! Zkuste to později.');
            dispatch({
                type: BUSSIGNS_ERROR
            });
        }
    }
);

export const patch = (data) => (
    async (dispatch, getState) => {

        try {
            dispatch({ type: BUSSIGNS_PASSWD_CHANGING });
            let id = data.id;
            delete data.id;
            await axios.patch(process.env.REACT_APP_API_BASE_URI + '/api/signs/' + id, data, { headers: { Authorization: `Bearer ${getAccessToken()}` } });
            setTimeout(() => {
                dispatch({
                    type: BUSSIGNS_PASSWD_CHANGING_SUCCESS,
                    item: Object.assign({}, data, { id: id })
                });
            }, Math.floor(Math.random() * 600) + 200);
        } catch (err) {
            message.error('Označník se nepodařilo editovat! Zkuste to později.');
            dispatch({
                type: BUSSIGNS_ERROR
            });
        }
    }
);

export const del = (id) => (
    async (dispatch, getState) => {

        try {
            dispatch({ type: BUSSIGNS_DELETING });
            await axios.delete(process.env.REACT_APP_API_BASE_URI + '/api/signs/' + id, { headers: { Authorization: `Bearer ${getAccessToken()}` } });
            dispatch({
                type: BUSSIGNS_DELETED_SUCCESS,
                item: Object.assign({}, { id: id })
            });
        } catch (err) {
            message.error('Označník se nepodařil smazat! Zkuste to později.');
            dispatch({
                type: BUSSIGNS_ERROR
            });
        }
    }
);
