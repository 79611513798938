import * as actions from '../actions/Drawer';

export default (
  state = {
    visible: false,
    title: null,
    content: null,
    width: 0
  },
  action
) => {
  switch (action.type) {
    case actions.DRAWER_OPEN:
      return {
        ...state,
        visible: true,
        title: action.title,
        content: action.content,
        width: action.width
      };
    case actions.DRAWER_CLOSE:
      return {
        ...state,
        visible: false,
        title: null,
        content: null,
        width: 0
      };
    default:
      return state;
  }
};
