import axios from 'axios';
import { getAccessToken } from './Users';

export const ROLES_FETCHING = "ROLES_FETCHING";
export const ROLES_FETCHED_SUCCESS = "BUSSIGNS_FETCHED_SUCCESS";
export const ROLES_ERROR = "BUSSIGNS_FETROLES_ERRORCHED_SUCCESS";

export const getRoles = () => {
    return new Promise((resolve, reject) => {
        let requestParams = {
            filter: {
                order: [
                    "key ASC"
                ]
            }
        }
        axios.get(process.env.REACT_APP_API_BASE_URI + '/api/roles', { params: requestParams, headers: { Authorization: `Bearer ${getAccessToken()}` } })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};