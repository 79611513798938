import axios from 'axios';
import { message } from 'antd';
import { getAccessToken } from './Users';

export const MESSAGES_FETCHING = "MESSAGES_FETCHING";
export const MESSAGES_FETCHED_SUCCESS = "MESSAGES_FETCHED_SUCCESS";
export const MESSAGES_DELETING = "MESSAGES_DELETING";
export const MESSAGES_DELETED_SUCCESS = "MESSAGES_DELETED_SUCCESS";
export const MESSAGES_ERROR = "MESSAGES_ERROR";

export const load = (dateFrom = null, dateTo = null) => (
    async (dispatch, getState) => {
        dispatch({
            type: MESSAGES_FETCHING
        });
        try {
            let requestParams = {
                filter: {
                    order: [
                        "timestamp DESC"
                    ],
                    where: {
                        and: []
                    },
                    include: [{
                        relation: 'sign'
                    }]
                }
            }
            if (dateFrom && dateTo) {
                requestParams.filter.where.and.push({ timestamp: { gte: dateFrom } });
                requestParams.filter.where.and.push({ timestamp: { lte: dateTo } });
            }

            let response = await axios.get(process.env.REACT_APP_API_BASE_URI + '/api/messages', { params: requestParams, headers: { Authorization: `Bearer ${getAccessToken()}` } });
            dispatch({
                type: MESSAGES_FETCHED_SUCCESS,
                items: response.data
            });
        } catch (err) {
            message.error('Zprávy se nepodařilo načíst! Zkuste to později');
            dispatch({
                type: MESSAGES_ERROR
            });
        }
    }
);

export const del = (id) => (
    async (dispatch, getState) => {

        try {
            dispatch({ type: MESSAGES_DELETING });
            await axios.delete(process.env.REACT_APP_API_BASE_URI + '/api/messages/' + id, { headers: { Authorization: `Bearer ${getAccessToken()}` } });
            dispatch({
                type: MESSAGES_DELETED_SUCCESS,
                item: Object.assign({}, { id: id })
            });
        } catch (err) {
            message.error('Zprávu se nepodařilo smazat! Zkuste to později.');
            dispatch({
                type: MESSAGES_ERROR
            });
        }
    }
);
