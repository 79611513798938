import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Select } from 'antd';
import { load } from '../actions/Operators';

const { Option } = Select;

class BusSignsOperatorSelect extends Component {
    //async componentWillMount() {
    _asyncRequest = this.props.load();
    //}

    handleChange = (value) => {
        this.props.setOperatorId(value);
    }

    render() {
        return (
            <Select defaultValue={this.props.selectedOperator} style={{ width: 300 }} placeholder="Vyberte operátora" onChange={this.handleChange}>
                {this.props.operators.items.map((operator, i) => {
                    return (<Option key={i} value={operator.id}>{operator.name}</Option>)
                })}
            </Select>
        )
    }
}
const mapStateToProps = state => ({
    operators: state.operators
});
export default connect(
    mapStateToProps,
    { load }
)(BusSignsOperatorSelect)