import React, { Component } from 'react'
import moment from 'moment';
import { connect } from 'react-redux';
import { Row, Col, Spin, Table, Button, DatePicker, ConfigProvider, Space, Empty, Badge } from 'antd';
import 'moment/locale/cs';
import locale from 'antd/es/locale/cs_CZ';
import { SyncOutlined } from '@ant-design/icons';
import { load } from '../actions/Alerts';
import { openDrawer } from '../actions/Drawer';

class AlertsList extends Component {

    state = {
        filteredInfo: null,
        dateFrom: moment().subtract(30, 'minutes'),
        dateTo: moment().endOf('day')
    }

    _asyncRequest = this.props.load(this.state.dateFrom, this.state.dateTo);

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            filteredInfo: filters
        });
    };

    onDateRangeChanged(dates, dateStrings) {
        this.setState({
            dateFrom: dates[0],
            dateTo: dates[1]
        });
        this.props.load(dates[0], dates[1]);
    }

    render() {
        let { filteredInfo } = this.state;
        filteredInfo = filteredInfo || {};
        if (this.props.alerts.isLoading) {
            return (
                <Spin />
            );
        } else {
            let modules = [];
            this.props.alerts.items.forEach(element => {
                if ((element.module) && modules.indexOf(element.module) === -1) {
                    modules.push(element.module);
                }
            });

            let modulesFilter = [];
            modules.forEach(module => {
                modulesFilter.push({ text: module, value: module });
            });
            return (
                <React.Fragment>
                    <Row>
                        <Col span="12">
                            <Space direction="vertical">
                                <ConfigProvider locale={locale}>
                                    <DatePicker.RangePicker
                                        defaultValue={[
                                            this.state.dateFrom,
                                            this.state.dateTo
                                        ]}
                                        ranges={{
                                            "Dnes": [moment().startOf('day'), moment().endOf('day')],
                                            "Včera": [moment().add(-1, 'days').startOf('day'), moment().add(-1, 'days').endOf('day')],
                                            "Poslední týden": [moment().add(-7, 'days').startOf('day'), moment().endOf('day')],
                                        }}
                                        allowClear={false}
                                        placeholder={['Od', 'Do']}
                                        format="DD.MM.YYYY HH:mm"
                                        showTime={{ format: 'HH:mm' }}
                                        onOk={this.onDateRangeChanged.bind(this)}
                                        style={{ width: 350 }} />
                                </ConfigProvider>
                            </Space>
                        </Col>
                        <Col span="12" style={{ textAlign: 'right' }}>
                            <Button
                                type="default"
                                icon={<SyncOutlined />}
                                onClick={() => { this.props.load(this.state.dateFrom, this.state.dateTo); }}
                                style={{ marginRight: 10 }}>
                            </Button>
                        </Col>
                    </Row>
                    <ConfigProvider renderEmpty={() => <Empty description="Žádné alerty" image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
                        <Table
                            rowKey="id"
                            locale={{
                                filterEmptyText: 'Žádné filtry',
                                triggerDesc: 'Klikni pro řazení sestupně',
                                triggerAsc: 'Klikni pro řazení vzestupně',
                                cancelSort: 'Klikni pro zrušení řazení',
                            }}
                            columns={[
                                {
                                    title: 'Modul',
                                    dataIndex: 'module',
                                    key: 'module',
                                    filters: modulesFilter,
                                    filteredValue: filteredInfo.module || null,
                                    onFilter: (value, record) => record.module === value,
                                    ellipsis: true,
                                    width: 200,
                                }, {
                                    title: 'Úroveň',
                                    dataIndex: 'level',
                                    key: 'level',
                                    width: 100,
                                    sorter: (a, b) => a.level - b.level,
                                }, {
                                    title: 'Zpráva',
                                    dataIndex: 'message',
                                    key: 'message'

                                }, {
                                    title: 'Reportováno',
                                    dataIndex: 'reported',
                                    key: 'reported',
                                    filters: [{
                                        text: 'ANO',
                                        value: true,
                                    }, {
                                        text: 'NE',
                                        value: false,
                                    }],
                                    width: 130,
                                    filteredValue: filteredInfo.reported || null,
                                    onFilter: (value, record) => { return ((record.reported === value)) ? true : false },
                                    render: (text, record) => {
                                        if (record.reported === true) {
                                            return (
                                                <Badge
                                                    status="success"
                                                    text="ANO" />
                                            );
                                        } else {
                                            return (
                                                <Badge
                                                    status="error"
                                                    text="NE" />
                                            );
                                        }
                                    }
                                }, {
                                    title: 'Čas zprávy',
                                    dataIndex: 'timestamp',
                                    key: 'timestamp',
                                    width: 200,
                                    sorter: (a, b) => a.timestamp.localeCompare(b.timestamp),
                                    render: (timestamp) => (
                                        timestamp ? moment(timestamp).format('DD. MM. YYYY HH:mm')
                                            : "-"
                                    )
                                }
                            ]}

                            dataSource={this.props.alerts.items}
                            onChange={this.handleChange}
                            pagination={{
                                total: this.props.alerts.length,
                                pageSize: this.props.alerts.length,
                                hideOnSinglePage: true
                            }}
                        />
                    </ConfigProvider>
                </React.Fragment>
            );

        }
    }
}
const mapStateToProps = state => ({
    alerts: state.alerts
});
export default connect(
    mapStateToProps,
    { load, openDrawer }
)(AlertsList)
