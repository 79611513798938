import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWheelchair } from '@fortawesome/free-solid-svg-icons'
import { Row, Spin, Col, Button, Table, Switch, ConfigProvider, Empty, Typography } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import moment from 'moment';
import { getDepartures } from '../actions/BusSigns';
import { closeDrawer } from '../actions/Drawer';
const { Title } = Typography;

class BusSignsDepartures extends React.Component {

    state = {
        autoReload: (localStorage.getItem('table_autoReload') !== undefined && localStorage.getItem('table_autoReload') === 'true') ? true : false,
    }

    // Checking auto reload data
    _asyncRequest = this.intervalId = setInterval(() => {
        if (this.state.autoReload) {
            this.props.getDepartures(this.props.serialNumber);
        }
    }, 30000);
    _asyncRequest = this.props.getDepartures(this.props.serialNumber);

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }
    render() {
        if (this.props.bussigns.isLoading2) {
            return (
                <Spin />
            );
        } else {
            return (
                <React.Fragment>
                    <Row>
                        <Col span="12">
                            <Title level={5}>Čas: {moment(this.props.bussigns.time).format('HH:mm')}</Title>
                        </Col>
                        <Col span="12" style={{ textAlign: 'right' }}>
                            <Switch
                                checked={this.state.autoReload}
                                onChange={(checked) => {
                                    localStorage.setItem('table_autoReload', checked);
                                    this.setState({
                                        autoReload: checked
                                    });
                                }} /> Automatická obnova
                            <Button
                                type="default"
                                icon={<SyncOutlined />}
                                onClick={() => { this.props.getDepartures(this.props.serialNumber) }}
                                style={{ marginRight: 10, marginLeft: 15 }}>
                            </Button>
                        </Col>
                    </Row>
                    <ConfigProvider renderEmpty={() => <Empty description="Žádné odjezdy" image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
                        <Table
                            rowKey="rowId"
                            columns={[
                                {
                                    title: 'Linka',
                                    dataIndex: 'alias',
                                    key: 'alias',
                                }, {
                                    title: 'Směr',
                                    dataIndex: 'directionToName',
                                    key: 'directionToName',
                                }, {
                                    title: 'Nástupiště',
                                    dataIndex: 'platformNo',
                                    key: 'platformNo'
                                }, {
                                    title: 'Nízkopodlažní',
                                    dataIndex: 'lowFloorBus',
                                    key: 'lowFloorBus',
                                    render: (lowFloorBus) => lowFloorBus ? <FontAwesomeIcon icon={faWheelchair} /> : null
                                }, {
                                    title: "Odjezd",
                                    dataIndex: "departure",
                                    key: "departure",
                                }
                            ]}

                            dataSource={this.props.bussigns.departures}
                            pagination={false}
                        />
                    </ConfigProvider>
                    <Row>
                        <Col span={24}>{this.props.bussigns.info}</Col>
                    </Row>
                </React.Fragment>

            );
        }
    }
}

const mapStateToProps = state => ({
    bussigns: state.bussigns
});

export default connect(
    mapStateToProps,
    { getDepartures, closeDrawer }
)(BusSignsDepartures)