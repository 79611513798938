import * as actions from '../actions/Users';

export default (
    state = {
        items: [],
        isLoading: false
    },
    action
) => {
    switch (action.type) {
        case actions.USERS_FETCHING:
            return {
                ...state,
                items: [],
                isLoading: true
            };
        case actions.USERS_FETCHED_SUCCESS:
            return {
                ...state,
                items: action.items,
                isLoading: false
            };
        case actions.USERS_ADDING:
            return {
                ...state,
                isLoading: true
            };
        case actions.USERS_ADDED_SUCCESS:
            return {
                ...state,
                items: state.items.concat([action.item]),
                isLoading: false
            };
        case actions.USERS_EDITING:
            return {
                ...state,
                isLoading: true
            };
        case actions.USERS_EDITTED_SUCCESS:
            let aItem = action.item;
            return {
                ...state,
                items: state.items.map((item) => {
                    return item.id === action.item.id
                        ? { ...item, ...aItem }
                        : item;
                }),
                isLoading: false
            };
        case actions.USERS_PATCHING:
            return {
                ...state,
                isLoading: true
            };
        case actions.USERS_PATCHED_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case actions.USERS_DELETING:
            return {
                ...state,
                isLoading: true
            };
        case actions.USERS_DELETED_SUCCESS:
            state.items.splice(state.items.indexOf(action.item), 1);
            return {
                ...state,
                items: state.items,
                isLoading: false
            };
        case actions.USERS_ERROR:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state;
    }
};
