import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Spin, Table, Button, Tooltip, Popconfirm, message, Row, Col, ConfigProvider, Empty } from 'antd';
import { PlusOutlined, DeleteOutlined, SyncOutlined } from '@ant-design/icons';
import { load, del } from '../actions/Operators';
import { openDrawer } from '../actions/Drawer';
import OperatorsForm from './OperatorsForm';
import { isInRoles } from '../actions/Users';

class OperatorsList extends Component {

    _asyncRequest = this.props.load();

    async deleteOperator(id) {
        await this.props.del(id);
        message.success('Operátor byl smazán');
        //await this.props.load();
    }
    render() {
        if (this.props.operators.isLoading) {
            return (
                <Spin />
            );
        } else {
            return (
                <React.Fragment>
                    <Row>
                        <Col span="12">
                            {isInRoles(['ADMIN', 'OPERATORS_WRITE']) ?
                                <Tooltip title="Přidat nového operátora">
                                    <Button
                                        key={1}
                                        type="link"
                                        icon={<PlusOutlined />}
                                        title="Vložit nového operátora"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.openDrawer(
                                                'Přidání nového operátora',
                                                <OperatorsForm />,
                                                800);
                                        }}
                                    />
                                </Tooltip>
                                : null
                            }
                        </Col>
                        <Col span="12" style={{ textAlign: 'right' }}>
                            <Button
                                type="default"
                                icon={<SyncOutlined />}
                                onClick={() => { this.props.load() }}
                                style={{ marginRight: 10, marginLeft: 15 }}>
                            </Button>
                        </Col>
                    </Row>
                    <ConfigProvider renderEmpty={() => <Empty description="Žádní operátoři" image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
                        <Table
                            rowKey="id"
                            locale={{
                                filterEmptyText: 'Žádné filtry',
                                triggerDesc: 'Klikni pro řazení sestupně',
                                triggerAsc: 'Klikni pro řazení vzestupně',
                                cancelSort: 'Klikni pro zrušení řazení',
                            }}
                            columns={[
                                {
                                    title: 'Název',
                                    dataIndex: 'name',
                                    key: 'name',
                                    sorter: (a, b) => a.name.localeCompare(b.name),
                                    defaultSortOrder: 'ascend',
                                }, {
                                    title: 'Popis',
                                    dataIndex: 'description',
                                    key: 'description',
                                }, {
                                    title: "URL modulu",
                                    dataIndex: "moduleURL",
                                    key: "moduleURL",
                                }, {
                                    title: 'Akce',
                                    dataIndex: 'actions',
                                    key: 'actions',
                                    render: (text, record, idx) => {
                                        if (isInRoles(['ADMIN', 'OPERATORS_WRITE'])) {
                                            return (
                                                <React.Fragment>
                                                    <Button
                                                        key={1}
                                                        type="link"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.openDrawer(
                                                                'Editace operátora: ' + record.name,
                                                                <OperatorsForm defaultValues={{
                                                                    id: record.id,
                                                                    name: record.name,
                                                                    description: record.description,
                                                                    moduleURL: record.moduleURL,
                                                                }} />,
                                                                800);
                                                        }}
                                                    >
                                                        edit
                                                    </Button>
                                                    <Popconfirm
                                                        title="Opravdu smazat?"
                                                        onConfirm={(e) => {
                                                            e.preventDefault();
                                                            this.deleteOperator(record.id);
                                                        }}
                                                        okText="Ano"
                                                        cancelText="Ne"
                                                        icon={<DeleteOutlined style={{ color: 'red' }} />}
                                                    >
                                                        <Button
                                                            key={2}
                                                            type="link"
                                                            danger
                                                        >
                                                            smazat</Button>
                                                    </Popconfirm>
                                                </React.Fragment>
                                            )
                                        }
                                    }
                                }
                            ]}
                            dataSource={this.props.operators.items}
                            pagination={{
                                total: this.props.operators.length,
                                pageSize: this.props.operators.length,
                                hideOnSinglePage: true
                            }}
                        />
                    </ConfigProvider>
                </React.Fragment>
            );

        }
    }
}
const mapStateToProps = state => ({
    operators: state.operators
});
export default connect(
    mapStateToProps,
    { load, del, openDrawer }
)(OperatorsList)
