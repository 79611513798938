import React, { Component } from 'react'
import { Button, Form, Input, message } from 'antd';
import { connect } from 'react-redux';
import { patch } from '../actions/BusSigns';
import { closeDrawer } from '../actions/Drawer';
import BusSignsPasswordFormItem from './BusSignsPasswordFormItem'

class BusSignsPasswordForm extends Component {
    formRef = React.createRef();

    componentDidMount() {
        this.formRef.current.setFieldsValue(this.props.defaultValues);
    }

    async onFinish(values) {
        if (values.confirmPassword) delete values.confirmPassword;
        await this.props.patch(values);
        await this.props.closeDrawer();
        //await this.props.load();
        await message.success('Označník byl editován');
    }

    onFinishFailed({ errorFields }) {
        console.log(errorFields)
        this.formRef.current.scrollToField(errorFields[0].name);
    }

    render() {
        return (
            <React.Fragment>
                <Form
                    labelCol={{
                        xs: {
                            span: 24,
                        },
                        sm: {
                            span: 8,
                        },
                    }}
                    wrapperCol={{
                        xs: {
                            span: 24,
                        },
                        sm: {
                            span: 16,
                        },
                    }}
                    onFinish={this.onFinish.bind(this)}
                    onFinishFailed={this.onFinishFailed.bind(this)}
                    ref={this.formRef}
                    scrollToFirstError
                >
                    <Form.Item name="id" label="ID" rules={[{ required: true }]} hidden={true}>
                        <Input disabled={true} />
                    </Form.Item>
                    <BusSignsPasswordFormItem />
                    <Form.Item wrapperCol={{ span: 13, offset: 5 }}>
                        <Button type="primary" htmlType="submit" loading={this.props.bussigns.isLoading}>
                            Uložit
                        </Button>
                    </Form.Item>
                </Form>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => ({
    bussigns: state.bussigns
});

export default connect(
    mapStateToProps,
    { patch, closeDrawer }
)(BusSignsPasswordForm)

