import React, { Component } from 'react'
import { Form, Input } from 'antd';

class UsersFromPasswordItems extends Component {

    render() {
        return (
            <React.Fragment>
                <Form.Item wrapperCol={{ span: 8 }}
                    name="password"
                    label="Heslo"
                    rules={[
                        { required: true, message: 'Vyplňte heslo!', },
                        { pattern: new RegExp("^[A-Za-z0-9+*%]*$"), message: "Nepovolený znak!" },
                        { min: 8, message: "Heslo musí mít minimálně 8 znaků!" },
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    wrapperCol={{ span: 8 }}
                    name="confirmPassword"
                    label="Potvrdit heslo"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Vyplňte heslo pro potvrzení!', },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('Zadaná hesla se neshodují!');
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
            </React.Fragment>
        )
    }
}

export default (UsersFromPasswordItems)