import React, { Component } from 'react'
import moment from 'moment';
import { connect } from 'react-redux';
import { Button, Badge, Row, Col, Spin, Tooltip, Table, Card, Popconfirm, message, Switch, ConfigProvider, Empty } from 'antd';
import { PlusOutlined, DeleteOutlined, SyncOutlined } from '@ant-design/icons';
import { load, del } from '../actions/BusSigns';
import { openDrawer } from '../actions/Drawer';
import BusSignsForm from './BusSignsForm';
import BusSignsPasswordForm from './BusSignsPasswordForm'
import BusSignsDepartures from './BusSignsDepartures';
import { isInRoles } from '../actions/Users';

class BusSignsList extends Component {

    state = {
        autoReload: (localStorage.getItem('table_autoReload') !== undefined && localStorage.getItem('table_autoReload') === 'true') ? true : false,
        filteredInfo: null,
    }

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            filteredInfo: filters
        });
    };

    // Checking auto reload data
    _asyncRequest = this.intervalId = setInterval(() => {
        if (this.state.autoReload) {
            this.props.load();
        }
    }, 30000);
    _asyncRequest = this.props.load();

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    async deleteSign(id) {
        await this.props.del(id);
        message.success('Označník byl smazán');
    }

    rowExpander(record) {
        return (
            <React.Fragment>
                <Row gutter={[16, 16]}>
                    <Col span={6}>
                        <Card title="Popis">{record.description ? record.description : "-"} </Card>
                    </Col>
                    <Col span={5}>
                        <Card title="Operátor">{record.operator ? record.operator.name : '-'} </Card>
                    </Col>
                    <Col span={6}>
                        <Card title="Číslo zastávky">
                            {record.busStopNo ? record.busStopNo : "-"}
                        </Card>
                    </Col>
                    <Col span={5}>
                        <Card title="Počet řádků">{record.numberOfRows}</Card>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={4}>
                        <Tooltip placement="right" title="interval= počet minut do odjezdu (včetně zpoždění), time= čas plus zpoždění (15:38 +2)">
                            <Card title="Môd">{record.mode}</Card>
                        </Tooltip>
                    </Col>
                    <Col span={5}>
                        {
                            record.position ?
                                <Card title="Umístění">

                                    <Tooltip placement="right" title="Zobrazení v mapě">
                                        <Button
                                            key={1}
                                            type="link"
                                            target="_blank"
                                            href={"https://www.google.com/maps/place/" + record.position.coordinates[1] + "," + record.position.coordinates[0]}>
                                            mapa
                                        </Button>
                                    </Tooltip>
                                </Card>
                                : "-"
                        }
                    </Col>
                    <Col span={6}>
                        <Card title="Poslední TTS">
                            {
                                record.ttsLastCommunication ?
                                    moment(record.ttsLastCommunication).format('DD. MM. YYYY HH:mm')
                                    : "-"
                            }
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card title="Počet TTS volání">
                            {record.numberOfTTSCalling.toLocaleString()}
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={5}>
                        <Tooltip placement="right" title="Ve výpisu odjezdů zobrazovat název města nebo ne">
                            <Card title="Skrýt město">
                                {
                                    record.hideCityName
                                        ? <Badge status='success' text="Ano" />
                                        : <Badge status='error' text="Ne" />
                                }
                            </Card>
                        </Tooltip>
                    </Col>
                    <Col span={6}>
                        <Card title="Datum vytvoření">
                            {
                                moment(record.createdAt).format('DD. MM. YYYY HH:mm')
                            }
                        </Card>
                    </Col>
                    <Col span={18}>
                        {
                            record.lastData ?
                                <Card title="poslední data" bordered={false} style={{ width: 750 }}>
                                    <Table
                                        rowKey="name"
                                        locale={{
                                            filterEmptyText: 'Žádné filtry',
                                            triggerDesc: 'Klikni pro řazení sestupně',
                                            triggerAsc: 'Klikni pro řazení vzestupně',
                                            cancelSort: 'Klikni pro zrušení řazení',
                                        }}
                                        columns={[
                                            {
                                                title: 'Název',
                                                dataIndex: 'name',
                                                key: 'name',
                                            }, {
                                                title: 'Data',
                                                dataIndex: 'data',
                                                key: 'data',
                                                render: (data) => (
                                                    Array.isArray(data) ?
                                                        <Table
                                                            rowKey="data"
                                                            columns={[
                                                                {
                                                                    title: 'Název',
                                                                    dataIndex: 'name',
                                                                    key: 'name',
                                                                }, {
                                                                    title: 'Data',
                                                                    dataIndex: 'data',
                                                                    key: 'data',
                                                                    render: (data) => (
                                                                        typeof (data) == 'boolean' ? data.toString() : data
                                                                    )
                                                                }
                                                            ]}
                                                            dataSource={data}
                                                            pagination={{
                                                                total: data.length,
                                                                pageSize: data.length,
                                                                hideOnSinglePage: true
                                                            }}
                                                        />
                                                        : typeof (data) == 'boolean' ? data.toString() : data
                                                )
                                            }, {
                                                title: 'Čas změny',
                                                dataIndex: 'updatedAt',
                                                key: 'updatedAt',
                                                width: 180,
                                                sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
                                                render: (updatedAt) => (
                                                    updatedAt ? moment(updatedAt).format('DD. MM. YYYY HH:mm')
                                                        : "-"
                                                )
                                            }
                                        ]}
                                        dataSource={record.lastData}
                                        pagination={{
                                            total: record.lastData.length,
                                            pageSize: record.lastData.length,
                                            hideOnSinglePage: true
                                        }}
                                    />
                                </Card>
                                : "-"
                        }

                    </Col>
                </Row>
            </React.Fragment>
        )
    }

    render() {
        let { filteredInfo } = this.state;
        filteredInfo = filteredInfo || {};
        if (this.props.bussigns.isLoading) {
            return (
                <Spin />
            );
        } else {
            return (
                <React.Fragment>
                    <Row>
                        <Col span="12">
                            {isInRoles(['ADMIN', 'SIGNS_WRITE'])
                                ? <Tooltip title="Přidat nový označník">
                                    <Button
                                        key={1}
                                        type="link"
                                        icon={<PlusOutlined />}
                                        title="Vložit nový označník"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.openDrawer(
                                                'Přidání nového označníku',
                                                <BusSignsForm />,
                                                800);
                                        }}
                                    />
                                </Tooltip>
                                : null}
                        </Col>
                        <Col span="12" style={{ textAlign: 'right' }}>
                            <Switch
                                checked={this.state.autoReload}
                                onChange={(checked) => {
                                    localStorage.setItem('table_autoReload', checked);
                                    this.setState({
                                        autoReload: checked
                                    });
                                }} /> Automatická obnova
                            <Button
                                type="default"
                                icon={<SyncOutlined />}
                                onClick={() => { this.props.load() }}
                                style={{ marginRight: 10, marginLeft: 15 }}>
                            </Button>
                        </Col>
                    </Row>
                    <ConfigProvider renderEmpty={() => <Empty description="Žádné označníky" image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
                        <Table
                            rowKey="id"
                            locale={{
                                filterEmptyText: 'Žádné filtry',
                                triggerDesc: 'Klikni pro řazení sestupně',
                                triggerAsc: 'Klikni pro řazení vzestupně',
                                cancelSort: 'Klikni pro zrušení řazení',
                            }}
                            columns={[
                                {
                                    title: 'Seriové číslo',
                                    dataIndex: 'serialNumber',
                                    key: 'serialNumber',
                                    sorter: (a, b) => a.serialNumber.localeCompare(b.serialNumber),
                                    defaultSortOrder: 'ascend'
                                }, {
                                    title: 'Nazev zastávky',
                                    dataIndex: 'busStopName',
                                    key: 'busStopName'
                                }, {
                                    title: 'Poslední komunikace',
                                    dataIndex: 'signLastCommunication',
                                    key: 'signLastCommunication',
                                    render: (signLastCommunication) => {
                                        if (!signLastCommunication) {
                                            return (
                                                "-"
                                            )
                                        } else {
                                            if (moment(signLastCommunication) <= moment().subtract(65, "minutes")) {
                                                return (
                                                    <Badge
                                                        status="error"
                                                        text={moment(signLastCommunication).format('DD. MM. YYYY HH:mm')}
                                                        title="Poslední komunikace před více než 65 minutami" />
                                                );
                                            } else if (moment(signLastCommunication) <= moment().subtract(35, "minutes")) {
                                                return (
                                                    <Badge
                                                        status="warning"
                                                        text={moment(signLastCommunication).format('DD. MM. YYYY HH:mm')}
                                                        title="Poslední komunikace před více než 35 minutami" />
                                                );
                                            } else {
                                                return (
                                                    <Badge
                                                        status="success"
                                                        text={moment(signLastCommunication).format('DD. MM. YYYY HH:mm')}
                                                        title="Vpořádku" />
                                                );
                                            }
                                        }
                                    },
                                    sorter: (a, b) => a.signLastCommunication.localeCompare(b.signLastCommunication)
                                }, {
                                    title: 'Komunikace s operátorem',
                                    dataIndex: 'operatorLastCommunication',
                                    key: 'operatorLastCommunication',
                                    render: (operatorLastCommunication) => {
                                        if (!operatorLastCommunication) {
                                            return (
                                                "-"
                                            )
                                        } else {
                                            if (moment(operatorLastCommunication) <= moment().subtract(65, "minutes")) {
                                                return (
                                                    <Badge
                                                        status="error"
                                                        text={moment(operatorLastCommunication).format('DD. MM. YYYY HH:mm')}
                                                        title="Poslední komunikace před více než 65 minutami" />
                                                );
                                            } else if (moment(operatorLastCommunication) <= moment().subtract(35, "minutes")) {
                                                return (
                                                    <Badge
                                                        status="warning"
                                                        text={moment(operatorLastCommunication).format('DD. MM. YYYY HH:mm')}
                                                        title="Poslední komunikace před více než 35 minutami" />
                                                );
                                            } else {
                                                return (
                                                    <Badge
                                                        status="success"
                                                        text={moment(operatorLastCommunication).format('DD. MM. YYYY HH:mm')}
                                                        title="Vpořádku" />
                                                );
                                            }
                                        }
                                    },
                                    sorter: (a, b) => a.operatorLastCommunication.localeCompare(b.operatorLastCommunication)
                                }, {
                                    title: 'Upozornění',
                                    dataIndex: 'reportsEnabled',
                                    key: 'reportsEnabled',
                                    width: 130,
                                    filters: [{
                                        text: 'ZAPNUTO',
                                        value: true,
                                    }, {
                                        text: 'VYPNUTO',
                                        value: false,
                                    }],
                                    filteredValue: filteredInfo.reportsEnabled || null,
                                    onFilter: (value, record) => { return ((record.reportsEnabled === value)) ? true : false },
                                    render: (text, record) => {
                                        if (record.reportsEnabled === true) {
                                            return (
                                                <Badge
                                                    status="success"
                                                    text="ZAPNUTO" />
                                            );
                                        } else {
                                            return (
                                                <Badge
                                                    status="error"
                                                    text="VYPNUTO" />
                                            );
                                        }
                                    }
                                }, {
                                    title: 'Akce',
                                    dataIndex: 'actions',
                                    key: 'actions',
                                    render: (text, record, idx) => {
                                        return (
                                            <React.Fragment>
                                                <Button
                                                    key={0}
                                                    type="link"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.openDrawer(
                                                            'Odjezdy pro ' + record.serialNumber,
                                                            <BusSignsDepartures
                                                                serialNumber={record.serialNumber} />,
                                                            1000
                                                        )
                                                    }}
                                                >
                                                    Odjezdy
                                                </Button>
                                                {isInRoles(['ADMIN', 'SIGNS_WRITE']) ?
                                                    <React.Fragment>
                                                        <Button
                                                            key={1}
                                                            type="link"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.props.openDrawer(
                                                                    'Editace označníku: ' + record.serialNumber,
                                                                    <BusSignsForm defaultValues={{
                                                                        id: record.id,
                                                                        serialNumber: record.serialNumber,
                                                                        description: record.description,
                                                                        busStopName: record.busStopName,
                                                                        busStopNo: record.busStopNo,
                                                                        numberOfRows: record.numberOfRows,
                                                                        operatorId: record.operator.id,
                                                                        hideCityName: record.hideCityName,
                                                                        mode: record.mode,
                                                                        reportsEnabled: record.reportsEnabled,
                                                                        positionLat: record.position ? record.position.coordinates[1] : "",
                                                                        positionLon: record.position ? record.position.coordinates[0] : "",
                                                                    }} />,
                                                                    800);
                                                            }}
                                                        >
                                                            edit
                                                        </Button>
                                                        <Button
                                                            key={2}
                                                            type="link"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.props.openDrawer(
                                                                    'Změna hesla označníku: ' + record.serialNumber,
                                                                    <BusSignsPasswordForm defaultValues={{
                                                                        id: record.id
                                                                    }} />,
                                                                    800);
                                                            }}
                                                        >
                                                            změnit heslo
                                                        </Button>
                                                        <Popconfirm
                                                            title="Opravdu smazat?"
                                                            onConfirm={(e) => {
                                                                e.preventDefault();
                                                                this.deleteSign(record.id);
                                                            }}
                                                            okText="Ano"
                                                            cancelText="Ne"
                                                            icon={<DeleteOutlined style={{ color: 'red' }} />}
                                                        >
                                                            <Button
                                                                key={3}
                                                                type="link"
                                                                danger
                                                            >
                                                                smazat</Button>
                                                        </Popconfirm>
                                                    </React.Fragment>
                                                    : null}
                                            </React.Fragment>
                                        )
                                    }
                                }
                            ]}
                            expandable={{
                                expandedRowRender: this.rowExpander,
                            }}
                            dataSource={this.props.bussigns.items}
                            onChange={this.handleChange}
                            pagination={{
                                total: this.props.bussigns.length,
                                pageSize: this.props.bussigns.length,
                                hideOnSinglePage: true
                            }}
                        />
                    </ConfigProvider>
                </React.Fragment>
            );

        }
    }
}
const mapStateToProps = state => ({
    bussigns: state.bussigns
});
export default connect(
    mapStateToProps,
    { load, del, openDrawer }
)(BusSignsList)
