import React, { Component } from 'react'
import moment from 'moment';
import { connect } from 'react-redux';
import { Row, Col, Spin, Table, Card, Button, DatePicker, ConfigProvider, Space, Empty } from 'antd';
import 'moment/locale/cs';
import locale from 'antd/es/locale/cs_CZ';
import { SyncOutlined } from '@ant-design/icons';
import { load } from '../actions/Messages';
import { openDrawer } from '../actions/Drawer';

class MessagesList extends Component {

    state = {
        filteredInfo: null,
        dateFrom: moment().subtract(30, 'minutes'),
        dateTo: moment().endOf('day')
    }

    _asyncRequest = this.props.load(this.state.dateFrom, this.state.dateTo);

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            filteredInfo: filters
        });
    };

    onDateRangeChanged(dates, dateStrings) {
        this.setState({
            dateFrom: dates[0],
            dateTo: dates[1]
        });
        this.props.load(dates[0], dates[1]);
    }


    rowExpander(record) {
        return (
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    {
                        record.data ?
                            <Card title="" bordered={false} style={{ width: 800 }}>
                                <Table
                                    rowKey="updatedAt"
                                    columns={[
                                        {
                                            title: 'Název',
                                            dataIndex: 'name',
                                            key: 'name',
                                        }, {
                                            title: 'Data',
                                            dataIndex: 'data',
                                            key: 'data',
                                            render: (data) => (
                                                Array.isArray(data) ?
                                                    <Table
                                                        rowKey="data"
                                                        columns={[
                                                            {
                                                                title: 'Název',
                                                                dataIndex: 'name',
                                                                key: 'name',
                                                            }, {
                                                                title: 'Data',
                                                                dataIndex: 'data',
                                                                key: 'data',
                                                                render: (data) => (
                                                                    typeof (data) == 'boolean' ? data.toString() : data
                                                                )
                                                            }
                                                        ]}
                                                        dataSource={data}
                                                        pagination={{
                                                            total: data.length,
                                                            pageSize: data.length,
                                                            hideOnSinglePage: true
                                                        }}
                                                    />
                                                    : typeof (data) == 'boolean' ? data.toString() : data
                                            )
                                        }, {
                                            title: 'Čas změny',
                                            dataIndex: 'updatedAt',
                                            key: 'updatedAt',
                                            sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
                                            render: (updatedAt) => (
                                                updatedAt ? moment(updatedAt).format('DD. MM. YYYY HH:mm')
                                                    : "-"
                                            )
                                        }
                                    ]}
                                    dataSource={record.data}
                                    pagination={{
                                        total: record.data.length,
                                        pageSize: record.data.length,
                                        hideOnSinglePage: true
                                    }}
                                />
                            </Card>
                            : "-"
                    }
                </Col>
            </Row>
        )
    }

    render() {
        let { filteredInfo } = this.state;
        filteredInfo = filteredInfo || {};
        if (this.props.messages.isLoading) {
            return (
                <Spin />
            );
        } else {
            let types = [];
            let signs = [];
            this.props.messages.items.forEach(element => {
                if ((element.name) && types.indexOf(element.name) === -1) {
                    types.push(element.name);
                }
                if (element.sign && signs.indexOf(element.sign.serialNumber) === -1) {
                    signs.push(element.sign.serialNumber);
                }
            });

            let typesFilter = [];
            types.forEach(type => {
                typesFilter.push({ text: type, value: type });
            });
            let signFilter = [];
            signs.forEach(sign => {
                signFilter.push({ text: sign, value: sign });
            });
            return (
                <React.Fragment>
                    <Row>
                        <Col span="12">
                            <Space direction="vertical">
                                <ConfigProvider locale={locale}>
                                    <DatePicker.RangePicker
                                        defaultValue={[
                                            this.state.dateFrom,
                                            this.state.dateTo
                                        ]}
                                        ranges={{
                                            "Dnes": [moment().startOf('day'), moment().endOf('day')],
                                            "Včera": [moment().add(-1, 'days').startOf('day'), moment().add(-1, 'days').endOf('day')],
                                            "Poslední týden": [moment().add(-7, 'days').startOf('day'), moment().endOf('day')],
                                        }}
                                        allowClear={false}
                                        placeholder={['Od', 'Do']}
                                        format="DD.MM.YYYY HH:mm"
                                        showTime={{ format: 'HH:mm' }}
                                        onOk={this.onDateRangeChanged.bind(this)}
                                        style={{ width: 350 }} />
                                </ConfigProvider>
                            </Space>
                        </Col>
                        <Col span="12" style={{ textAlign: 'right' }}>
                            <Button
                                type="default"
                                icon={<SyncOutlined />}
                                onClick={() => { this.props.load(this.state.dateFrom, this.state.dateTo); }}
                                style={{ marginRight: 10 }}>
                            </Button>
                        </Col>
                    </Row>
                    <ConfigProvider renderEmpty={() => <Empty description="Žádné zprávy" image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
                        <Table
                            rowKey="id"
                            locale={{
                                filterEmptyText: 'Žádné filtry',
                                triggerDesc: 'Klikni pro řazení sestupně',
                                triggerAsc: 'Klikni pro řazení vzestupně',
                                cancelSort: 'Klikni pro zrušení řazení',
                            }}
                            columns={[
                                {
                                    title: 'Typ zprávy',
                                    dataIndex: 'name',
                                    key: 'name',
                                    filters: typesFilter,
                                    filteredValue: filteredInfo.name || null,
                                    onFilter: (value, record) => record.name === value,
                                    ellipsis: true
                                }, {
                                    title: 'Značka',
                                    dataIndex: 'signId',
                                    key: 'signId',
                                    filters: signFilter,
                                    filteredValue: filteredInfo.signId || null,
                                    onFilter: (value, record) => {
                                        if (record.sign) {
                                            return record.sign.serialNumber === value;
                                        }
                                    },
                                    ellipsis: true,
                                    render: (text, record, idx) => {
                                        return record.sign ? record.sign.serialNumber : '-'
                                    }
                                }, {
                                    title: 'Čas zprávy',
                                    dataIndex: 'timestamp',
                                    key: 'timestamp',
                                    sorter: (a, b) => a.timestamp.localeCompare(b.timestamp),
                                    render: (timestamp) => (
                                        timestamp ? moment(timestamp).format('DD. MM. YYYY HH:mm')
                                            : "-"
                                    )
                                }
                            ]}
                            expandable={{
                                expandedRowRender: this.rowExpander,
                            }}
                            dataSource={this.props.messages.items}
                            onChange={this.handleChange}
                            pagination={{
                                total: this.props.messages.length,
                                pageSize: this.props.messages.length,
                                hideOnSinglePage: true
                            }}
                        />
                    </ConfigProvider>
                </React.Fragment>
            );

        }
    }
}
const mapStateToProps = state => ({
    messages: state.messages
});
export default connect(
    mapStateToProps,
    { load, openDrawer }
)(MessagesList)
