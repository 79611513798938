import React, { createContext } from "react";
import moment from 'moment';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Layout, Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Protected from './Protected';
import { userLogin } from "../actions/Users";

export default function AuthExample() {
  return (
    <ProvideAuth>
      <Router>
        <div>
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <PrivateRoute path="/">
              <Protected />
            </PrivateRoute>
          </Switch>
        </div>
      </Router>
    </ProvideAuth>
  );
}

const authContext = createContext();

function ProvideAuth({ children }) {
  const isAuth = localStorage.getItem('user');
  return (
    <authContext.Provider value={isAuth}>
      {children}
    </authContext.Provider>
  );
}

function PrivateRoute({ children, ...rest }) {
  const isAuth = localStorage.getItem('user');

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function LoginPage() {
  let history = useHistory();
  let location = useLocation();

  let { from } = location.state || { from: { pathname: "/signs" } };
  let login = (values) => {
    userLogin(values)
      .then(() => {
        history.replace(from);
      })
      .catch(err => {
        if (err.response) {
          message.warning(err.response.data.error.message);
        } else {
          message.error('Nastala neočekávaná chyba při přihlašování!');
        }
      });
  };
  const { Content } = Layout;
  return (
    <Content style={{ margin: '24px 16px 0' }}>
      <div style={{ padding: 24, width: 320, boxShadow: '0 0 100px rgba(0,0,0,.08)', position: 'absolute', top: '50%', left: '50%', margin: '-160px 0 0 -160px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className="logo" style={{ width: 170, height: 38, background: 'url("https://smart4city.cz/img/s4c_logo_1.png")', backgroundSize: '100% 100%', margin: 16, marginBottom: 30 }} />
        </div>
        <Form onFinish={login} className="login-form">
          <Form.Item name="userName" rules={[
            { required: true, message: "Zadejte prosím uživatelské jméno!" },
          ]}>
            <Input prefix={<UserOutlined />} placeholder="Uživatelské jméno" />
          </Form.Item>
          <Form.Item name="password" rules={[
            { required: true, message: 'Zadejte prosím heslo!' },
          ]}>
            <Input prefix={<LockOutlined />} type="password" placeholder="Heslo" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
              Přihlásit
            </Button>
          </Form.Item>
        </Form>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ fontSize: 10 }}>Smart4City ©{moment().format('YYYY')} Created by SPEL a.s.</p>
        </div>
      </div>
    </Content>
  );
}
