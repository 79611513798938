import React from 'react';
import { Button, Form, Input, message, Slider, Tooltip, Switch, Select, Divider } from 'antd';
import { connect } from 'react-redux';
import { add, edit, load } from '../actions/BusSigns';
import { closeDrawer } from '../actions/Drawer';

import BusSignsOperatorSelect from './BusSignsOperatorSelect';
import BusSignsPasswordFormItem from './BusSignsPasswordFormItem';

const { Option } = Select;

class BusSignsForm extends React.Component {

    formRef = React.createRef();

    componentDidMount() {
        this.formRef.current.setFieldsValue(this.props.defaultValues);
    }

    async onFinish(values) {
        let position = {
            "type": "Point",
            "coordinates": [
                values.positionLon,
                values.positionLat
            ]
        };
        if (values.confirmPassword) delete values.confirmPassword;
        if (values.positionLat && values.positionLon) { values.position = position };
        delete values.positionLon;
        delete values.positionLat;
        if (values.id === undefined) {
            await this.props.add(values);
            this.formRef.current.resetFields();
            await this.props.closeDrawer();
            await this.props.load();
            await message.success('Označník byl vložen');
        } else {
            await this.props.edit(values);
            await this.props.closeDrawer();
            await this.props.load();
            await message.success('Označník byl editován');
        }
    }

    onFinishFailed({ errorFields }) {
        console.log(errorFields)
        this.formRef.current.scrollToField(errorFields[0].name);
    }

    setOperatorIdFromChild = (operatorId) => {
        this.formRef.current.setFieldsValue({
            operatorId: operatorId,
        });
    }

    render() {
        return (
            <Form
                labelCol={{
                    xs: {
                        span: 24,
                    },
                    sm: {
                        span: 8,
                    },
                }}
                wrapperCol={{
                    xs: {
                        span: 24,
                    },
                    sm: {
                        span: 16,
                    },
                }}
                onFinish={this.onFinish.bind(this)}
                onFinishFailed={this.onFinishFailed.bind(this)}
                ref={this.formRef}
                initialValues={{ hideCityName: true, mode: "interval", numberOfRows: 3 }}
                scrollToFirstError
            >
                {this.props.defaultValues && this.props.defaultValues.id
                    ? <Form.Item name="id" label="ID" rules={[{ required: true }]} hidden={true}>
                        <Input disabled={true} />
                    </Form.Item>
                    : null}

                <Form.Item wrapperCol={{ span: 11 }} name="serialNumber" label="Sériové číslo" rules={[
                    { required: true, message: 'Vyplňte sériové číslo!' },
                    { pattern: new RegExp("^[A-Za-z0-9-]*$"), message: "Nepovolený znak!" },
                ]}
                    hasFeedback
                >
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 11 }} name="description" label="Popis">
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 11 }} name="busStopName" label="Název zastávky" rules={[{ required: true, message: 'Vyplňte název zastávky!' }]} hasFeedback>
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 6 }} name="busStopNo" label="Číslo zastávky" rules={[{ required: true, message: 'Vyplňte číslo zastávky!' }]} hasFeedback>
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 11 }} name="operatorId" label="Operator" rules={[{ required: true, message: 'Vyberte operátora!' }]} hasFeedback>
                    {(this.props.defaultValues && this.props.defaultValues.id) ?
                        <BusSignsOperatorSelect selectedOperator={this.props.defaultValues.operatorId} setOperatorId={this.setOperatorIdFromChild} />
                        : <BusSignsOperatorSelect setOperatorId={this.setOperatorIdFromChild} />}
                </Form.Item>
                <Form.Item wrapperCol={{ span: 6 }} name="positionLat" label="Zeměpisná šířka">
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 6 }} name="positionLon" label="Zeměpisná délka">
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 11 }} name="numberOfRows" label="Počet zobrazovaných řádků">
                    <Slider
                        marks={{
                            1: '1',
                            5: '5',
                            10: '10',
                            15: '15',
                            20: '20',
                            25: '25',
                            30: '30'
                        }}
                        min={1}
                        max={30}
                    />
                </Form.Item>
                <Tooltip title="U vodárny (Ano) || Kolín, U vodárny (Ne)">
                    <Form.Item wrapperCol={{ span: 5, offset: 1 }} name="hideCityName" label="Skrýt město ve výpisu" valuePropName="checked">
                        <Switch
                            checkedChildren="Ano"
                            unCheckedChildren="Ne"
                        />
                    </Form.Item>
                </Tooltip>
                <Tooltip title="interval (2 min) || time (15:38+2)">
                    <Form.Item wrapperCol={{ span: 5 }} name="mode" label="Mod zobrazování">
                        <Select style={{ width: 120 }}>
                            <Option value="interval">interval</Option>
                            <Option value="time">time</Option>
                        </Select>
                    </Form.Item>
                </Tooltip>
                {this.props.defaultValues && this.props.defaultValues.id ?
                    <React.Fragment>
                        <Form.Item wrapperCol={{ span: 7, offset: 1 }} name="reportsEnabled" label="Upozornění" valuePropName="checked">
                            <Switch
                                checkedChildren="Ano"
                                unCheckedChildren="Ne"
                            />
                        </Form.Item>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Divider />
                        <BusSignsPasswordFormItem />
                        <Divider />
                    </React.Fragment>
                }
                <Form.Item wrapperCol={{ span: 13, offset: 5 }}>
                    <Button type="primary" htmlType="submit" loading={this.props.bussigns.isLoading}>
                        {this.props.defaultValues && this.props.defaultValues.id
                            ? "Uložit"
                            : "Přidat"}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

const mapStateToProps = state => ({
    bussigns: state.bussigns
});

export default connect(
    mapStateToProps,
    { add, edit, load, closeDrawer }
)(BusSignsForm)